/* eslint-disable prettier/prettier */
import { BigNumber, ethers } from 'ethers';

import farmsList from '../data/farmsEthList';
import farmsSepoliaList from '../data/farmsSepoliaList';

import { LinksType, WalletAsset } from './types';

export const TEST_ACCOUNT = ''; // use to test wallet, always commit as empty string
export const EMPTY_ACCOUNT = '0x9d4c0Bc73609C195038cb74Cc8313C99fBa5752a';

export const BASE_URL = process.env.GATSBY_BASE_URL;
export const LIFI_KEY = process.env.GATSBY_LIFI_KEY;
export const LIFI_WALLET = process.env.GATSBY_LIFI_WALLET;
export const AMPLITUDE_KEY = process.env.GATSBY_AMPLITUDE_KEY;
export const STYTCH_KEY = process.env.GATSBY_STYTCH_KEY;
export const WALLETCONNECT_PROJECT_ID =
  process.env.GATSBY_WALLETCONNECT_PROJECT_ID;
export const KILN_API_KEY = process.env.GATSBY_KILN_API_KEY;
export const KILN_TESTNET_API_KEY = process.env.GATSBY_KILN_TESTNET_API_KEY;

export const RPC_URL_ETHEREUM = process.env.GATSBY_RPC_URL_ETHEREUM;
export const RPC_URL_SEPOLIA = process.env.GATSBY_RPC_URL_SEPOLIA;
export const RPC_URL_SMARTBCH = process.env.GATSBY_RPC_URL_SMARTBCH;
export const RPC_URL_AVALANCHE = process.env.GATSBY_RPC_URL_AVALANCHE;
export const RPC_URL_POLYGON = process.env.GATSBY_RPC_URL_POLYGON;
export const RPC_URL_BINANCE = process.env.GATSBY_RPC_URL_BINANCE;
export const RPC_URL_HOLESKY = process.env.GATSBY_RPC_URL_HOLESKY;

export const CUSTOM_RPC_OPTION = {
  chainName: 'VERSE private RPC',
  chainId: '0x1',
  nativeCurrency: {
    name: 'ETH',
    decimals: 18,
    symbol: 'ETH',
  },
  rpcUrls: [
    'https://eth.blinklabs.xyz/v1/a79e1201f07a4f1d0ca6e0b027d7e9b2b4b6c81c088392d92b219c3c183d6d2b',
  ],
  blockExplorerUrls: ['https://etherscan.io'],
};

export const ACTIVE_ENV =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const STYTCH_EMAIL_TEMPLATES: Record<string, Record<string, string>> = {
  login: {
    production: 'email-template-live-7c212b5c-8a22-45f9-a406-0a52bd2feb89',
    staging: 'email-template-live-7c212b5c-8a22-45f9-a406-0a52bd2feb89',
    development: 'email-template-test-29d147a8-3f51-413b-a97f-107b2e2d6e8c',
  },
  signup: {
    production: 'email-template-live-88f95df5-9eba-48c1-af54-4526f2f88e2a',
    staging: 'email-template-live-88f95df5-9eba-48c1-af54-4526f2f88e2a',
    development: 'email-template-test-db24bba1-d38e-4bc8-b96c-53e8bd51ea87',
  },
};

export const ETH_CONTRACT = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';

export const VERSE_ETHEREUM_TOKEN_CONTRACT =
  '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18';
export const VERSE_POLYGON_TOKEN_CONTRACT =
  '0xc708D6F2153933DAA50B2D0758955Be0A93A8FEc';

export const VERSE_CLAIM_CONTRACT =
  '0xE5aC5142BdE69cfA722662D9C3E4C8111f60B8d5';

export const VERSE_ETH_PAIR_ADDRESS =
  '0x845C0179060362f071FF5C7f1D2703617a480F3e';
export const SEPOLIA_VERSE_ETH_PAIR =
  '0x05755d84E3C8b4E147e55c6bB35C0c4b45189692';
export const BCH_FLEXUSD_PAIR = '0xa79e824e4d7e93ca1b305ff821c7de598360542b';

export const DEFAULT_ROUTER = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
export const DEFAULT_FACTORY = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
export const DEFAULT_LIQUIDITY_MAKER =
  '0x7D3caD2EF18689625231Fc793Ac11c1676FdeDCC';
export const DEFAULT_STAKING_CONTRACT =
  '0xDED0C22aCd80e7a4bd6eC91ced451Fc83f04cAB2';

export const USDT_WZANO_FARM =
  '0x3E587eb9E7843999c4BC67719c41B80425563aF8';
export const INACTIVE_USDT_WZANO_FARM =
  '0xa51f4C17bb902fF996Ccd766cE072C7CE30E95F4';

// verse balances contracts
export const VERSE_BALANCES_ETH_CONTRACT =
  '0x3B089972C36578cf6EAb8e7F2dAD3b63c27bEE07';
export const VERSE_BALANCES_SEPOLIA_CONTRACT =
  '0x3489a8cc6923bd2E88A2835509EB1241B737929f';
export const VERSE_BALANCES_POLYGON_CONTRACT =
  '0x44A72D580098571DA827aC8f6908aA0700455Bdc';

// Transaction log data types
export const ETH_TRANSFER_SIG =
  '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef';

export const VERSE_BALANCES_CONTRACTS: Record<number, string> = {
  1: VERSE_BALANCES_ETH_CONTRACT,
  11155111: VERSE_BALANCES_SEPOLIA_CONTRACT,
  137: VERSE_BALANCES_POLYGON_CONTRACT,
};

export const APPROVE_MAX_VALUE = ethers.constants.MaxUint256;
export const BLOCK_CONFIRMATION = 1;
export const DEFAULT_BIGNUM = BigNumber.from(0);
export const DEFAULT_DECIMALS = 18;
export const DEFAULT_VALUE = '0.00';
export const EMPTY = '';
export const PRECISION = 6;

export const MILLISECONDS_IN_DAY = 86400000;
export const MILLISECONDS_ADJUST = 1000;
export const SECONDS_IN_YEAR = 60 * 60 * 24 * 365;

export type TxType =
  | 'APPROVE_COIN0'
  | 'APPROVE_COIN1'
  | 'CONFIRM'
  | 'PENDING'
  | 'SUCCESS';

export const TX_STATUS: Record<string, TxType> = {
  ApproveCoin0: 'APPROVE_COIN0',
  ApproveCoin1: 'APPROVE_COIN1',
  Confirm: 'CONFIRM',
  Pending: 'PENDING',
  Success: 'SUCCESS',
};

export const EXPLORER_LINKS: LinksType = {
  avax: 'https://snowtrace.io/tx/',
  bch: 'https://blockchair.com/bitcoin-cash/transaction/',
  bnb: 'https://bscscan.com/tx/',
  btc: 'https://blockchair.com/bitcoin/transaction/',
  eth: 'https://etherscan.io/tx/',
  ltc: 'https://ltc.bitaps.com/',
  matic: 'https://polygonscan.com/tx/',
  xlm: 'https://stellarchain.io/tx/',
  dash: 'https://explorer.dash.org/insight/tx/',
};

export const PROVIDER_ICON_URLS: LinksType = {
  bitcoincom:
    'https://raw.githubusercontent.com/mistswapdex/assets/master/blockchains/smartbch/assets',
  bitcoincom_eth:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets',
  bitcoincom_seth:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets',
};

export const MULTICHAIN_DETAILS = {
  label: 'Multichain',
  icon: '/images/uploads/multichain.png',
};

type LiFiChainType = {
  chainId: number;
  nativeCurrency: string;
  nativeProtocol: string;
  tokenProtocol: string;
  explorer: string;
};

export const LIFI_CHAINS: Record<string, LiFiChainType> = {
  ETH_BLOCKCHAIN: {
    chainId: 1,
    nativeCurrency: 'ETH',
    nativeProtocol: 'ETH_PROTOCOL',
    tokenProtocol: 'ERC_20_PROTOCOL',
    explorer: EXPLORER_LINKS.eth,
  },
  AVAX_BLOCKCHAIN: {
    chainId: 43114,
    nativeCurrency: 'AVAX',
    nativeProtocol: 'AVAX_PROTOCOL',
    tokenProtocol: 'ERC_20_PROTOCOL',
    explorer: EXPLORER_LINKS.avax,
  },
  MATIC_BLOCKCHAIN: {
    chainId: 137,
    nativeCurrency: 'MATIC',
    nativeProtocol: 'MATIC_PROTOCOL',
    tokenProtocol: 'ERC_20_PROTOCOL',
    explorer: EXPLORER_LINKS.matic,
  },
  BNB_BLOCKCHAIN: {
    chainId: 56,
    nativeCurrency: 'BNB',
    nativeProtocol: 'BNB_PROTOCOL',
    tokenProtocol: 'ERC_20_PROTOCOL',
    explorer: EXPLORER_LINKS.bnb,
  },
};

export const ETHEREUM_CHAIN = 1;
export const SEPOLIA_ETH_CHAIN = 11155111;
export const SMARTBCH_CHAIN = 10000;
export const DEFAULT_CHAIN = ETHEREUM_CHAIN;
export const DEFAULT_AMOUNT = '1';

export const PROVIDER_CHAINS: Record<string, string> = {
  bitcoincom: 'SBCH',
  bitcoincom_eth: 'ETH',
  bitcoincom_seth: 'SETH',
  bitcoincom_heth: 'HETH',
  changenow: 'centralized',
  sideshift: 'centralized',
};

export const CHAIN_NAMES: LinksType = {
  AVAX_BLOCKCHAIN: 'Avalanche',
  BNB_BLOCKCHAIN: 'BSC',
  ETH_BLOCKCHAIN: 'Ethereum',
  MATIC_BLOCKCHAIN: 'Polygon',
  SBCH_BLOCKCHAIN: 'Smart BCH',
};

export const SUPPORTED_CHAIN_LIST = [
  ETHEREUM_CHAIN,
  SMARTBCH_CHAIN,
  SEPOLIA_ETH_CHAIN,
];

export const ETH_CHAIN_NETWORKS = ['bitcoincom_eth', 'bitcoincom_seth'];

export const ALL_NATIVE_SELECTORS = ['ETH', 'BNB', 'MATIC', 'BCH', 'WETH'];

// TODO: Re-examine how this is being used, it's being applied to several different contexts
export const NATIVE_SELECTOR = (selector: string | undefined): boolean => {
  if (!selector) return false;
  const chain = selector.split('_')[0];
  return ALL_NATIVE_SELECTORS.includes(chain);
};

export const PROVIDER_NATIVE_TOKENS: Record<string, string> = {
  bitcoincom: 'BCH',
  bitcoincom_eth: 'ETH',
  bitcoincom_seth: 'ETH',
};

export const ETH_TESTNET_PROVIDERS = ['bitcoincom_seth'];

export const CLIENT_ROUTES = [
  '/:chain/',
  '/:chain/:pair/',
  '/:chain/:pair/deposit/',
  '/:chain/:pair/withdraw/',
];

export const BRIDGE_TOKENS = ['SMART_BCH'];

export const TOKEN_PROTOCOLS = ['ERC_20_PROTOCOL', 'LIQUID_PROTOCOL'];

export const EVM_COMPATIBLE_PROTOCOLS = [
  'ETH_PROTOCOL',
  'ERC_20_PROTOCOL',
  'SBCH_PROTOCOL',
  'MATIC_PROTOCOL',
  'BNB_PROTOCOL',
];

export const NULL_WALLET = {
  coin: '',
  name: '',
  walletId: '',
  color: '',
  isHidden: false,
  isShared: false,
  assets: [] as WalletAsset[],
};

export const NULL_PAIR = {
  id: '',
  name: '',
  token0Price: '',
  token1Price: '',
  reserveUSD: '',
  totalSupply: '',
  token0: {
    derivedETH: '',
    name: '',
    symbol: '',
    id: '',
    decimals: '',
  },
  token1: {
    derivedETH: '',
    name: '',
    symbol: '',
    id: '',
    decimals: '',
  },
};

// for fallback Gas rates
export const GAS_DEFAULTS = {
  addLiquidity: BigNumber.from(184085),
  addLiquidityETH: BigNumber.from(196852),
  removeLiquidity: BigNumber.from(158569),
  removeLiquidityETH: BigNumber.from(182830),
  farmDeposit: BigNumber.from(116896),
  farmWithdraw: BigNumber.from(79775),
  exitFarm: BigNumber.from(110999),
  claimReward: BigNumber.from(104218),
};

// ITickerObject
// TODO: can import elsewhere, i.e. farms, etc
export const VERSE_TOKEN = {
  abbr: 'VERSE',
  blockchain: 'ETH_BLOCKCHAIN',
  explorer: 'https://etherscan.io/tx/',
  label: 'Verse',
  protocol: 'ERC_20_PROTOCOL',
  ticker: 'VERSE',
  token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
  value: 'VERSE',
  decimals: 18,
  icon: 'https://markets.bitcoin.com/images/coins/verse.png',
};

export const MATIC_ERC_TOKEN = {
  abbr: 'MATIC',
  blockchain: 'ETH_BLOCKCHAIN',
  explorer: 'https://etherscan.io/tx/',
  label: 'Matic',
  protocol: 'ERC_20_PROTOCOL',
  ticker: 'MATIC',
  token: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
  value: 'MATIC',
  decimals: undefined,
};

export const tabletBreakpoints = {
  xsmall: '810px',
  small: '855px',
  medium: '940px',
  large: '1000px',
  xlarge: '1300px',
};

export const DEFAULT_ERROR_MESSAGE =
  'An error occurred while processing this transaction. Your funds are safe. Please try again later or contact Support.';

export const USER_REJECTED_TEXTS = [
  'user rejected',
  'Error approving',
  'Request Rejected',
];

export const REWARDS_RATE = 0.0025;

export const FARMS_ARR: Record<string, IFarmPair[]> = {
  bitcoincom_eth: farmsList,
  bitcoincom_seth: farmsSepoliaList,
};
